import { useState } from 'react';
import type { DatePickerProps } from 'antd';
import { Input } from 'antd';
import { Button, DatePicker, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { payoutInvoicesTableFiltersSelectors } from 'store/invoices/payout/payoutInvoicesTableFiltersSelectors';
import { payoutInvoicesTableSelectors } from 'store/invoices/payout/payoutInvoicesTableSelectors';
import { usePayoutInvoiceTable } from 'store/invoices/payout/usePayoutInvoiceTable';
import { usePayoutInvoiceTableFilters } from 'store/invoices/payout/usePayoutInvoiceTableFilters';
import { merchantsSelectors } from 'store/merchants/merchantsSelectors';
import { useMerchants } from 'store/merchants/useMerchants';
import MultiSelect from 'ui/controls/MultiSelect';
import { Search } from 'ui/controls/Search';
import SingleSelect from 'ui/controls/SingleSelect';
import { dateHelpers } from 'utils/helpers/dateHelpers';
import { predicates } from 'utils/helpers/predicates';
import type { TComponent } from 'utils/types/component';

import omitBy from 'lodash/omitBy';

import { InvoicesExportModalButton } from 'components/domains/Invoices/components/InvoicesExportModalButton';

import styles from './Filters.module.scss';
import { filtersHelpers } from './helpers';
import type { IFilterForm } from './types';

const INITIAL_FILTERS: IFilterForm = {
    searchStr: '',
    externalUserId: '',
    externalIdentity: '',
    currencyCode: null,
    createdAtFrom: '',
    createdAtTo: '',
    amountFrom: '',
    amountTo: '',
    statuses: [],
};

interface IFiltersProps {
    onExport: (email: string) => void;
}

export const Filters: TComponent<IFiltersProps> = (props) => {
    const { onExport } = props;

    const { t } = useTranslation();

    const [form] = Form.useForm<IFilterForm>();
    const [formData, setFormData] = useState<IFilterForm>(INITIAL_FILTERS);

    const params = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.getParams,
    );
    const clearBody = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.clearBody,
    );
    const setBody = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.setBody,
    );
    const setParams = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.setParams,
    );
    const currenciesOptions = useMerchants(
        merchantsSelectors.currenciesOptions,
    );
    const statusesOptions = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.statusesOptions,
    );
    const getMany = usePayoutInvoiceTable(
        payoutInvoicesTableSelectors.getTable,
    );

    const initStartDate = formData.createdAtFrom
        ? dateHelpers.formatDays(formData.createdAtFrom)
        : null;
    const initEndDate = formData.createdAtTo
        ? dateHelpers.formatDays(formData.createdAtTo)
        : null;

    const handleClearFilters = () => {
        form.resetFields();

        setFormData(INITIAL_FILTERS);

        clearBody();

        getMany({}, params);
    };

    const onSubmit = () => {
        const enhanceData = omitBy(
            filtersHelpers.getFiltersData(formData),
            (field) =>
                predicates.isUndefined(field) ||
                predicates.isEmptyString(field) ||
                predicates.isEmptyArray(field),
        );

        const newParams = { ...params, page: 0 };

        setBody(enhanceData);
        setParams(newParams);

        getMany(enhanceData, newParams);
    };

    const handleChangeField =
        <K extends keyof IFilterForm>(field: K) =>
        (value: IFilterForm[K]) => {
            setFormData((data) => ({
                ...data,
                [field]: value,
            }));
        };

    const handleChangeStartDate: DatePickerProps['onChange'] = async (
        _,
        startDate,
    ) => handleChangeField('createdAtFrom')(startDate);
    const handleChangeEndDate: DatePickerProps['onChange'] = async (
        _,
        endDate,
    ) => handleChangeField('createdAtTo')(endDate);

    const handleChangeSearchStr = handleChangeField('searchStr');
    const handleChangeExternalUserId = handleChangeField('externalUserId');
    const handleChangeExternalIdentity = handleChangeField('externalIdentity');
    const handleChangeAmountFrom = handleChangeField('amountFrom');
    const handleChangeAmountTo = handleChangeField('amountTo');
    const handleChangeCurrency = handleChangeField('currencyCode');
    const handleChangeStatuses = handleChangeField('statuses');

    return (
        <Form form={form} onFinish={onSubmit} className={styles.leftControls}>
            <div className={styles.filtersContainer}>
                <Search
                    value={formData.searchStr}
                    onChange={handleChangeSearchStr}
                    className={styles.search}
                    placeholder={t('common.form.search')}
                />
                <Input
                    value={formData.externalUserId}
                    onChange={(e) => handleChangeExternalUserId(e.target.value)}
                    className={styles.search}
                    placeholder={t('common.form.userId')}
                />
                <Input
                    value={formData.externalIdentity}
                    onChange={(e) =>
                        handleChangeExternalIdentity(e.target.value)
                    }
                    className={styles.search}
                    placeholder={t('common.form.identity')}
                />
                <Input
                    value={formData.amountFrom}
                    onChange={(e) => handleChangeAmountFrom(e.target.value)}
                    className={styles.search}
                    placeholder={t('common.form.amountFrom')}
                />
                <Input
                    value={formData.amountTo}
                    onChange={(e) => handleChangeAmountTo(e.target.value)}
                    className={styles.search}
                    placeholder={t('common.form.amountTo')}
                />
            </div>
            <div className={styles.filtersContainer}>
                <SingleSelect
                    value={formData.currencyCode}
                    onChange={handleChangeCurrency}
                    placeholder={t('common.form.currency')}
                    options={currenciesOptions}
                    className={styles.select}
                />
                <MultiSelect
                    value={formData.statuses}
                    onChange={handleChangeStatuses}
                    placeholder={t('common.form.status')}
                    options={statusesOptions}
                    maxTagCount='responsive'
                    className={styles.select}
                />
                <DatePicker
                    value={initStartDate}
                    onChange={handleChangeStartDate}
                    placeholder={t('common.form.dateFrom')}
                    className={styles.datePicker}
                />
                <DatePicker
                    value={initEndDate}
                    onChange={handleChangeEndDate}
                    placeholder={t('common.form.dateTo')}
                    className={styles.datePicker}
                />
            </div>
            <div className={styles.controlsContainer}>
                <div className={styles.buttonsContainer}>
                    <Button type='primary' htmlType='submit'>
                        {t('common.buttons.apply')}
                    </Button>
                    <Button type='default' onClick={handleClearFilters}>
                        {t('common.buttons.clear')}
                    </Button>
                </div>

                <div className={styles.rightControls}>
                    <InvoicesExportModalButton sendReport={onExport} />
                </div>
            </div>
        </Form>
    );
};
